@import "../../../../index.scss";

.detalle-estudio {
	padding: $gap;
	#primary-button {
		@include primary-button;
	}

	#secondary-button {
		@include secondary-button;
	}
}

.input-file,
.dropdown {
	// padding: $gap;
	margin: $gap 0;
	display: block;
	width: 100%;
}

.input-button {
	display: none;
}

.botonera {
	margin-top: $gap3x;
	display: flex;
	justify-content: space-around;
}

#primary-button {
	@include primary-button;
	padding: 20px 30px;
	margin: 0;
}
